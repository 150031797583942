$.fn.extend({
    'highlight': function (options: Record<string, any>) {
        options = $.extend({
            keyword: "",
        }, options)

        if (options.keyword === "") {
            return
        }

        const pattern = "\(" + regexpSafeText(options.keyword) + "\)"
        const regex = new RegExp(pattern, "g")
        $(this).each(function () {
            const plain = $(this).html()
            const highlighted = plain.replace(regex, "<span class='highlight'>$1</span>")
            $(this).html(highlighted)
        })
    },
})

function regexpSafeText(str: string) {
    return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
}
