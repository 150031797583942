$.fn.extend({
    'grepWiki': function (options: Record<string, any>) {
        options = $.extend({
            wikiListRequestPath: null,
            grepWikiRequestPath: null,
            wikiManagementUrl: null,
            execButton: null,
            cancelButton: null,
            searchTargetSelect: null,
            searchTargetWikiContainer: null,
            searchTargetWikiInput: null,
            searchWordInput: null,
            searchTypeSelect: null,
            pageNameTooCheck: null,
            searchStatus: null,
            searchNum: null,
            searchResult: null,
            grepInterval: 100,
        }, options)

        $(options.searchTargetSelect).on('change', function () {
            if ($(this).val() === 'specify') {
                $(options.searchTargetWikiContainer).show()
            } else {
                $(options.searchTargetWikiContainer).hide()
            }
        })

        let status = {
            searchStatus: '',
            message: '',
            searchCompleteNum: 0,
            searchTargetNum: 0,
        }

        $(options.execButton).on('click', function () {
            let wikiList: string[] = []
            clearStatus()
            $(options.searchResult).empty()
            status.searchStatus = 'searching'
            renderStatus()

            const searchTarget = $(options.searchTargetSelect + ':checked').val()
            if (searchTarget === 'specify') {
                const inputVal: string = $(options.searchTargetWikiInput).val().trim()
                if (inputVal.length === 0) {
                    displayError('検索対象wikiを指定してください。')
                    return
                }
                wikiList = inputVal.split(/\s+/)
                grepEachWiki(wikiList)
            } else {
                $.ajax({
                    url: options.wikiListRequestPath,
                    type: 'GET',
                    dataType: 'json',
                }).done(function (data) {
                    wikiList = data
                    grepEachWiki(wikiList)
                }).fail(function (_jqXHR, _textStatus, errorThrown) {
                    displayError('最近更新されたwikiリストの取得中にエラーが発生しました。: ' + errorThrown)
                })
            }
        })

        $(options.cancelButton).on('click', function () {
            status.searchStatus = 'canceled'
        })

        function grepEachWiki(wikiList: string[]) {
            const searchWord = $(options.searchWordInput).val().trim()
            if (searchWord.length === 0) {
                displayError('検索語を指定してください。')
                return
            }

            const searchType = $(options.searchTypeSelect + ':checked').val()
            const pageNameToo = $(options.pageNameTooCheck).prop('checked') ? 1 : 0

            const count = wikiList.length
            status.searchTargetNum = count

            const grepWikiRequest = function () {
                if (status.searchStatus === 'canceled' || status.searchStatus === 'error') {
                    renderStatus()
                    return
                } else if (status.searchCompleteNum >= count) {
                    status.searchStatus = 'complete'
                    renderStatus()
                    return
                }

                $.ajax({
                    url: options.grepWikiRequestPath,
                    type: 'GET',
                    dataType: 'json',
                    data: {
                        'wiki': wikiList[status.searchCompleteNum],
                        'searchWord': searchWord,
                        'searchType': searchType,
                        'pageNameToo': pageNameToo,
                    },
                }).done(function (data: Record<string, any>) {
                    const wiki = wikiList[status.searchCompleteNum]

                    if (data.result === 'error') {
                        appendErrorResult(wiki, data.message)
                    } else {
                        appendGrepResult(data.wikiInfo, data.pages)
                    }

                    status.searchCompleteNum++
                    renderStatus()

                    setTimeout(grepWikiRequest, options.grepInterval)

                }).fail(function (_jqXHR, _textStatus, errorThrown) {
                    displayError(wikiList[status.searchCompleteNum] + ' の全文検索中にエラーが発生しました。: ' + errorThrown)
                })
            }

            grepWikiRequest()
        }

        function clearStatus() {
            status.searchStatus = ''
            status.message = ''
            status.searchCompleteNum = 0
            status.searchTargetNum = 0
        }

        function renderStatus() {
            if (status.searchStatus === 'error') {
                $(options.searchStatus).addClass('text-danger')
                $(options.searchStatus).text('エラー ' + status.message)
                $(options.execButton).show()
                $(options.cancelButton).hide()
            } else if (status.searchStatus === 'canceled') {
                $(options.searchStatus).removeClass('text-danger')
                $(options.searchStatus).text('検索を中断しました。')
                $(options.execButton).show()
                $(options.cancelButton).hide()
            } else if (status.searchStatus === 'searching') {
                $(options.execButton).hide()
                $(options.cancelButton).show()
                $(options.searchStatus).removeClass('text-danger')
                $(options.searchStatus).text('検索中')
                if (status.searchTargetNum > 0) {
                    $(options.searchNum).text(status.searchCompleteNum + ' / ' + status.searchTargetNum)
                } else {
                    $(options.searchNum).text('')
                }
            } else if (status.searchStatus === 'complete') {
                $(options.searchStatus).removeClass('text-danger')
                $(options.searchStatus).text('検索完了')
                $(options.execButton).show()
                $(options.cancelButton).hide()
            }
        }

        function displayError(message: string) {
            status.searchStatus = 'error'
            status.message = message
            renderStatus()
        }

        function appendGrepResult(wikiInfo: Record<string, any>, pages: Record<string, any>[]) {
            if (pages.length === 0) {
                return
            }

            const $result = $(options.searchResult)
            appendWikiList(wikiInfo)
            const $wikiResultList = $result.find('ul#' + uniqueWikiID(wikiInfo.id))

            $.each(pages, function (_, page) {
                $wikiResultList.append('<li><sapn>' + page.updatedAt + '</sapn> <a href="' + page.url + '" target="_blank">' + page.page + '</a></li>')
            })

            activateReadMore()
        }

        function activateReadMore() {
            $(".page-list").readmoreCollapse({
                collapsedHeight: 200,
                moreLink: '<button class="readmore-btn"><i class="fa-solid fa-chevron-down"></i></button>',
                lessLink: '<button class="readmore-btn"><i class="fa-solid fa-chevron-up"></i></button>',
            })
        }

        function appendErrorResult(wiki: string, message: string) {
            const $result = $(options.searchResult)
            $result.append(
                '<li>' +
                '<h3><a class="wiki-management-link text-success" href="' + options.wikiManagementUrl + '?id=' + wiki + '" target="_blank">' + wiki + '</a></h3>' +
                '<ul id="' + uniqueWikiID(wiki) + '" class="page-list"></ul>' +
                '</li>',
            )
            const $wikiResultList = $result.find('ul#' + uniqueWikiID(wiki))
            message = '全文検索中にエラーが発生しました。: ' + message
            $wikiResultList.append('<li class="text-danger">' + message + '</li>')
        }

        function appendWikiList(wikiInfo: Record<string, any>) {
            const $result = $(options.searchResult)
            $result.append(
                '<li>' +
                '<h3>' +
                '<strong><a href="' + wikiInfo.publicUrl + '" target="_blank">' + wikiInfo.title + ' Wiki* </a></strong>' +
                '<a class="wiki-management-link text-success" href="' + wikiInfo.managementUrl + '" target="_blank">' + wikiInfo.id + '</a>' +
                '</h3>' +
                '<ul id="' + uniqueWikiID(wikiInfo.id) + '" class="page-list"></ul>' +
                '</li>',
            )
        }

        function uniqueWikiID(wiki: string) {
            return wiki + '-' + status.searchCompleteNum
        }
    },
})
