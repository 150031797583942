const SCROLL_ANIMATION_DURATION = 100
const SCROLL_ANIMATION_RANGE = 200
const SCROLL_TARGET_BOTTOM_MARGIN = 200

const EVENT_RESPONDER_REPEAT_LIMIT = 6
const EVENT_RESPONDER_REPEAT_DELAY_MS = 500

$.fn.extend({
    'scrollToTopBottom': function (options: Record<string, any>) {
        options = $.extend({
            top: null,
            bottom: null,
        }, options)

        const $container = $(this)

        function topElement() {
            const e = $container.find(options.top)
            return e.length > 0 ? e : $container.find(':first')
        }

        function bottomElement() {
            const e = $container.find(options.bottom)
            return e.length > 0 ? e : $container.find(':last')
        }

        const toTop = $(
            '<button class="scroll-to-top-bottom scroll-to-top-bottom-top"><i class="fa fa-arrow-up"></i></button>',
        ).on('click', function () {
            const targetPos = topElement().offset().top
            $('html,body').scrollTop(
                targetPos + SCROLL_ANIMATION_RANGE,
            ).animate({
                scrollTop: targetPos,
            }, SCROLL_ANIMATION_DURATION)
            // console.log('toTop')
            updateButtonsVisibility()
            return false
        }).appendTo(document.body)

        const toBottom = $(
            '<button class="scroll-to-top-bottom scroll-to-top-bottom-bottom"><i class="fa fa-arrow-down"></i></button>',
        ).on('click', function () {
            const targetPos = bottomElement().offset().top - $(window).height() +
                bottomElement().outerHeight() + SCROLL_TARGET_BOTTOM_MARGIN
            $('html,body').scrollTop(
                targetPos - SCROLL_ANIMATION_RANGE,
            ).animate({
                scrollTop: targetPos,
            }, SCROLL_ANIMATION_DURATION)
            // console.log('toBottom')
            updateButtonsVisibility()
            return false
        }).appendTo(document.body)

        function updateButtonsVisibility() {
            const sp = $(window).scrollTop()
            if (sp > topElement().offset().top) {
                toTop.show()
            } else {
                toTop.hide()
            }
            if (sp + $(window).height() < bottomElement().offset().top) {
                toBottom.show()
            } else {
                toBottom.hide()
            }
        }

        let scrolling = null
        const eventResponder = function () {
            if (scrolling) {
                clearInterval(scrolling)
            }
            let repeat = 0
            scrolling = setInterval(function () {
                // console.log('to');
                updateButtonsVisibility()
                if (++repeat > EVENT_RESPONDER_REPEAT_LIMIT) {
                    clearInterval(scrolling)
                    scrolling = null
                }
            }, EVENT_RESPONDER_REPEAT_DELAY_MS)
        }
        $(window).on('scroll', eventResponder).on('resize', eventResponder).trigger('scroll')
    },
})
