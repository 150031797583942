$.fn.extend({
    'autolink': function (this: JQuery, options: Record<string, any>) {
        options = $.extend({
            noreferrer: "",
        }, options)

        const referrerOption = options.noreferrer === "1" ? " rel=noreferrer" : ""
        const pattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig
        this.each(function () {
            $(this).html($(this).html().replace(pattern, "<a href='$1' target='_blank'" + referrerOption + ">$1</a>"))
        })
    },
})
