// noinspection HtmlRequiredAltAttribute

import Identicon from 'identicon.js'

$.fn.extend({
    'identicon': function (options: Record<string, any>) {
        options = $.extend({
            identiconOption: {},
        }, options)

        $(this).each(function () {
            const $userData = $(this)
            const source = $userData.data('hash')
            const data = new Identicon(source, options.identiconOption).toString()

            $userData.append(
                $('<img>').attr({
                    src: 'data:image/png;base64,' + data,
                    alt: source,
                    draggable: false,
                }),
            )
        })
    },
})
