/**
 * 未読新着の位置にスクロールまたはページをまたいで移動するための jQuery プラグイン
 */
$.fn.extend({
    'diffLogScrollToBorder': function (options: Record<string, any>) {
        options = $.extend({
            borderDiffHash: null,
            borderSelector: null,
            buttonSelector: null,
            pageLoadingWait: 100,
            scrollSpeed: 500,
        }, options)

        onLoaded()

        // Pjax で（後で）差し込まれた要素にも当てるため document のイベントリスナに登録する必要がある
        $(document).on('click', options.buttonSelector, function () {
            if ($(options.borderSelector).length > 0) {
                // 表示中のページ内に存在すれば、ページ内スクロール
                scrollToAnker(options.borderDiffHash)
            } else {
                const url = $(this).data('url')
                const container = '#' + $('[data-pjax-container]').attr('id')
                $.pjax({url: url, container: container})
            }
        })

        $(document).on('pjax:success', function () {
            onLoaded()
        })

        function onLoaded() {
            const urlHash = location.hash
            if (urlHash) {
                $('body,html').stop().scrollTop(0)

                setTimeout(function () {
                    scrollToAnker(urlHash)
                }, options.pageLoadingWait)
            }
        }

        function scrollToAnker(hash: string) {
            const target = hash === options.borderDiffHash ? $(options.borderSelector) : $(hash)
            const position = target.offset().top - $(window).height() / 2
            $('body,html').stop().animate({scrollTop: position}, options.scrollSpeed)
        }
    },
})
