$.fn.extend({
    /**
     * ポーリングして未読新着件数の表示を更新する jQuery プラグイン。BackOffice 専用の想定。
     * @param options
     */
    'diffLogNewArrivalPolling': function (options: Record<string, any>) {
        options = $.extend({
            containerSelector: null,
            buttonSelector: null,
            inlineButtonSelector: null,
            requestIntervalInMilliSec: 10000,
            maxRequestCount: 0,
        }, options)

        // Pjax で異なる DOM 要素に置き換わるので、要素インスタンスを保持せず、セレクタで毎回
        // 再取得するポリシー

        const $container = $(this)

        const state = {
            did: null,
            cycle: 0,
            timeout: null,
            pendingAjax: false,
        }

        function updateParams() {
            state.did = fetchLastDid()
        }

        function start() {
            if (state.timeout) {
                return
            }
            state.pendingAjax = false
            state.timeout = setTimeout(function () {
                var pollingUrl = $(options.containerSelector).data('polling-url')
                if (!pollingUrl) {
                    return
                }
                poll(pollingUrl, function () {
                    state.timeout = null
                }, function () {
                    if (options.maxRequestCount > 0 && ++state.cycle >= options.maxRequestCount) {
                        return
                    }
                    start()
                })
            }, options.requestIntervalInMilliSec)
        }

        function stop() {
            if (state.timeout) {
                clearTimeout(state.timeout)
                state.pendingAjax = true
                state.timeout = null
            }
        }

        function poll(url, beforePoll, afterPoll) {
            beforePoll()
            $.ajax(url, {
                dataType: 'json',
            }).done(function (data) {
                if (state.pendingAjax) {
                    return
                }

                $(options.inlineButtonSelector).text('これ以後 ' + data.count + ' 件を表示')
                $(options.buttonSelector).text('これ以後 ' + data.count + ' 件の更新差分を表示')
            }).fail(function () {
                // nop?
            }).always(function () {
                if (state.pendingAjax) {
                    return
                }
                afterPoll()
            })
        }

        function canPolling() {
            return true
            // return !!state.did;
        }

        function fetchLastDid() {
            var did = $container.find(options.inlineButtonSelector).data('last-did')
            return did ? did : ''
        }

        $(window).on('pjax:start', function () {
            stop()
        }).on('pjax:end', function () {
            updateParams()
            if (canPolling()) {
                start()
            }
        })

        updateParams()
        if (canPolling()) {
            start()
        }
    },
})
