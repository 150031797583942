const COLLAPSE_CLASS = "collapse-state"

$.fn.extend({
    'readmoreCollapse': function (this: JQuery, options: Record<string, any>) {
        options = $.extend({
            collapsedHeight: 200,
            moreLink: '<button class="readmore-btn">more</button>',
            lessLink: '<button class="readless-btn">less</button>',
        }, options)

        function collapse($target: JQuery) {
            $target.addClass(COLLAPSE_CLASS)
            $target.css("height", options.collapsedHeight)
        }

        function expand($target: JQuery) {
            $target.removeClass(COLLAPSE_CLASS)
            $target.css("height", '')
        }

        function moreButtonFor($target: JQuery) {
            return $(options.moreLink).on('click', function (e) {
                e.preventDefault()
                expand($target)
                $(this).replaceWith(lessButtonFor($target))
            })
        }

        function lessButtonFor($target: JQuery) {
            return $(options.lessLink).on('click', function (e) {
                e.preventDefault()
                collapse($target)
                $(this).replaceWith(moreButtonFor($target))
            })
        }

        $(this).each(function (_, target) {
            const $target = $(target)
            if ($target.outerHeight() > options.collapsedHeight) {
                collapse($target)
                moreButtonFor($target).insertAfter(target)
            }
        })
    },
})
