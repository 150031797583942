import '../common/framework-preset'
import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-icons/font/bootstrap-icons.css'
import '@fortawesome/fontawesome-pro/css/all.css'

// Fancybox 3.x は途中で NPM やめて Bower しかやらなくなった
import '../../vendor/bower-asset/fancybox/dist/jquery.fancybox.css'
import '../../vendor/bower-asset/fancybox/dist/jquery.fancybox.js'

import '../common/lib/scroll-to-top-bottom'
import '../common/lib/readmore-collapse'  // jquery.grep-wiki.js が使う
import '../common/lib/autolink'

import '../common/js/jquery.identicon'

import '../common/css/bs3compat/index.css'
import './css/site.css'

import '../common/css/diff-coloring.css'

import './css/diffana.css'

import './js/jquery.grep-wiki'
import './js/jquery.highlight'
import './js/jquery.diff-log-scroll-to-border'
import './js/jquery.diff-log-new-arrival-polling'

import 'c3/c3.css'
window.c3 = require('c3/c3')
window.URI = require('urijs/src/URI')
